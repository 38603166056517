body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*This is to block text selection for user*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appLoader {
    background-color: #ffffff90;
    z-index: 10;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
}

.container {
    position: relative;
}

.footer {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.scrollable {
    overflow: scroll;
}

.parent {
    position: relative;
}

.backdrop {
    background-color: #0B0C0Cee;
    z-index: 11;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
}

.helpview {
    z-index: 20;
    position: absolute;
}

.app-modal {
    z-index: 20;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.appScanner {
    position: absolute;
    z-index: -1;
}